import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";

import Tickets from "./openedTickets";
import ClosedTickets from "./closedTickets";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import TicketsStats from "./ticketstats";
import { connect, useDispatch } from 'react-redux';

import { collection, doc, onSnapshot, orderBy, query, where, getDocs, getDoc, limit, startAfter, startAt } from "firebase/firestore";
import { firestore } from "../../Firebase/firebaseConfig";

import {
   setOpenedTickets, setClosedTickets
} from "../../store/data/actions";

function formatDate(date) {
    const pad = (n) => (n < 10 ? '0' + n : n);
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.0000`;
}


const TicketsPage = (props) => {
    document.title = "TicketControl - Tickets";
    const [activeTab2, setactiveTab2] = useState("5");
    const { currentCompany } = props
    const dispatch = useDispatch();


    const toggle2 = (tab) => {
        if (activeTab2 !== tab) {
            setactiveTab2(tab);
        }
    };


    // ///////// TICKETS //////////////

    // useEffect(() => {

    //     const fetchData = async () => {
    //         console.log('fetchData TICKETS');
    //         const ticketsRef = collection(firestore, "users", currentCompany.id, "tickets");
    //         const q = query(ticketsRef, orderBy('init_date', 'desc'), where('closed', '==', 0));
    //         // Obtener documentos iniciales
    //         const initialQuerySnapshot = await getDocs(q);
    //         const initialDocuments = [];
    //         let last_doc = null;
    //         initialQuerySnapshot.forEach((doc) => {
    //             const docData = doc.data();
    //             if (last_doc == null) {
    //                 last_doc = docData.init_date;
    //             }
    //             initialDocuments.push(docData);
    //         });
    //         dispatch(setOpenedTickets(initialDocuments));
            
            
            


    //         // TICKETS CERRADOS
    //         // Calcula la fecha de hace 30 días y formatea
    //         const now = new Date();
    //         const thirtyDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));
    //         const thirtyDaysAgoString = formatDate(thirtyDaysAgo);

    //         const q2 = query(
    //             ticketsRef,
    //             where('closed', '==', 1),
    //             where('init_date', '>=', thirtyDaysAgoString),
    //             orderBy('init_date', 'desc')
    //         );
            
    //         const initialQuerySnapshot2 = await getDocs(q2);
    //         const initialDocuments2 = [];
    //         initialQuerySnapshot2.forEach((doc) => {
    //             initialDocuments2.push(doc.data());
    //         });
    //         dispatch(setClosedTickets(initialDocuments2));

    //     };

    //     fetchData();
      
    // }, [props.currentCompany.id]);


    ///////// TICKETS //////////////



    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TicketControl" breadcrumbItem="Tickets" />

                    {/* <CardTitle className="h4">Tickets</CardTitle> */}
                    {/* <p className="card-title-desc">Seleccione</p> */}
                    <Row>
                        <Col>
                            <TicketsStats />
                        </Col>
                    </Row>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab2 === "5",
                                })}
                                onClick={() => {
                                    toggle2("5");
                                }}
                            >
                                <i className="dripicons-home me-1 align-middle"> </i>{" "}
                                Tickets Abiertos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab2 === "6",
                                })}
                                onClick={() => {
                                    toggle2("6");
                                }}
                            >
                                <i className="dripicons-user me-1 align-middle"></i>{" "}
                                Tickets Cerrados
                            </NavLink>
                        </NavItem>


                    </Nav>

                    <TabContent activeTab={activeTab2} className="p-3 text-muted">
                        <TabPane tabId="5">
                            <Row>
                                <Col sm="12">
                                    <CardText className="mb-0">
                                        <Row>
                                            <Tickets />
                                        </Row>
                                    </CardText>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="6">
                            <Row>
                                <Col sm="12">
                                    <CardText className="mb-0">
                                        <Row>
                                            <ClosedTickets/>
                                        </Row>
                                    </CardText>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>



                </Container>
            </div>
        </>
    );
}


const mapDispatchToProps = (dispatch) => ({

})

const mapStateToProps = (state) => ({
    currentCompany: state.companies.currentCompany
})

export default connect(mapStateToProps, mapDispatchToProps)(TicketsPage);
