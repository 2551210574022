import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Card, CardBody, CardHeader, Row, Container, CardTitle, CardFooter, CardText } from "reactstrap";
import { fetchOpenedTickets, setOpenedTickets } from "../../store/data/actions";
import { connect } from 'react-redux';
import moment from 'moment';
import "moment/locale/es";
import { Modal, ModalBody } from "reactstrap";
import { useState } from "react";

// import TicketsStats from "./ticketstats";


const Tickets = (props) => {

    const customer_id = props.currentCompany.id;
    const openedTickets = useSelector((state) => state.data.openedTickets);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image) => {
        setSelectedImage(image);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedImage(null);
    };

    // if openedTickets is not array 
    // or openedTickets is empty array
    // then return null
    if (!Array.isArray(openedTickets) || !openedTickets.length) {
        return null;
    }

    const getType = (type) => {
        switch (type) {
            case 'hour':
                return 'xHora';
            case 'stay':
                return 'xEstadia';
            case 'tn':
                return 'xTurno';
            default:
                return '--';
        }
    };


    const TimeAgo = ({ date }) => {
        moment.locale("es");
        const timeAgo = moment(date).fromNow();

        return <span>{timeAgo}</span>;
    };

    const getColor = (colorName, opacity) => {
        const colors = {
            blue: [11, 177, 151],


            indigo: [86, 74, 177],
            purple: [86, 100, 210],
            hour: [86, 100, 210],

            pink: [232, 62, 140],
            red: [255, 61, 96],
            tn: [255, 61, 96],


            orange: [241, 115, 79],

            yellow: [252, 185, 44],
            tn: [252, 185, 44],


            green: [10, 192, 116],
            stay: [10, 192, 116],


            teal: [5, 5, 5],
            cyan: [74, 163, 255],
        };

        const color = colors[colorName.toLowerCase()];

        if (!color) {
            console.warn(`Color '${colorName}' not found. Defaulting to black.`);
            return `rgba(0, 0, 0, ${opacity})`; // Default to black if color is not found
        }

        return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;
    };



    return (
        <React.Fragment>

            <Row>
                {openedTickets.map((item, index) => {

                    const bgcolor = getColor(item.type, .6);
                    return (
                        <Col xs={12} sm={6} md={4} lg={4} xl={3} xxl={2} key={index} style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                            gap: '1rem',
                            
}}>
                            <Card className={item.type == 'hour' ? " card text-center" : " card  text-center"} style={{ backgroundColor: getColor(item.type, .2) }}>
                                <CardHeader className="h4  text-uppercase " style={{ backgroundColor: getColor(item.type, .2) }}>
                                    <h3 className="align-center  p-0 mb-0 "><strong>#{item.plate}</strong></h3>
                                </CardHeader>
                                {item.picture && (
                                    <img
                                        src={`data:image/png;base64,${item.picture}`}
                                        alt="ticket"
                                        className="w-100 mb-0"
                                        style={{
                                            maxHeight: '100px',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => openModal(`data:image/png;base64,${item.picture}`)}
                                    />
                                )}

                                <CardBody className="text-center " >

                                    {/* Picture iamge base64 */}
                                   

                                    <h5 className=' text-uppercase mb-0'>{item.category_name}</h5>
                                    {item.car_detail && <div>{item.car_detail}</div>}

                                    <h6 className='mt-0 pt-0  '>{getType(item.type)}</h6>

                                    <div>{moment(item.init_date).format('DD/MM/YY HH:mm')}</div>



                                    <TimeAgo date={item.init_date} />

                                    {item.obs && <CardText className="text-center mt-2" style={{backgroundColor:'yellow', borderRadius:5}}>{item.obs}</CardText>}
                                    

                                </CardBody>
                                <CardFooter className="  card mb-0 text-white" style={{ backgroundColor: getColor(item.type, .2) }}><small>CAJERO: {item.fullName.toUpperCase()}</small></CardFooter>

                            </Card>

                        </Col>
                    )
                })}
            </Row>
            
            <Modal isOpen={modalOpen} toggle={closeModal} size="lg" centered>
                <ModalBody className="text-center">
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="ticket full-size"
                            className="img-fluid"
                            style={{ maxHeight: '80vh', objectFit: 'contain' }}
                        />
                    )}
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        openedTickets: state.data.openedTickets, // data es la propiedad en el estado de Redux donde almacenas los datos
        customer_id: state.data.customer_id,
        currentCompany: state.companies.currentCompany,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOpenedTickets: () => dispatch(fetchOpenedTickets()),
        setOpenedTickets: () => dispatch(setOpenedTickets()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Tickets);