import React, { useEffect, useState } from 'react';
import { Container, Alert } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const ServerUrl = process.env.REACT_APP_SERVER_URL

const Page = () => {

    const [licence, setLicence] = useState(false);
    const [date, setDate] = useState(false);
    const [key, setKey] = useState(false);
    const [limit, setLimit] = useState(0);
    const [withInvoices, setWithInvoices] = useState(true);
    const [withAnpr, setWithAnpr] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [licenceDecrypt, setLicenceDecrypt] = useState(false);
    const [licenceDecryptText, setLicenceDecryptText] = useState(false);
    document.title = "Creación de Licencia | TicketControl - Admin";

    const copyToClipboard = () => {
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = licence;
        tempTextArea.style.position = 'fixed';
        tempTextArea.style.top = '0';
        tempTextArea.style.left = '0';
        tempTextArea.style.opacity = '0';
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);

        // Mostrar el mensaje de éxito
        setShowAlert(true);

        // Ocultar el mensaje después de unos segundos
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };


    const handleSubmitDectipt = async (event) => {
        event.preventDefault();

        if (!licenceDecrypt || licenceDecrypt.length < 1) {
            setLicenceDecryptText(false);
            return;
        }

       
        const apiKey = process.env.REACT_APP_APIKEY_LICENCE
        // Realizar la solicitud al servidor
        try {

            const response = await fetch(`${ServerUrl}/decrypt`, {
                method: 'POST',
                cors: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                },
                body: JSON.stringify({"licence": licenceDecrypt}),
            });

            // Manejar la respuesta del servidor
            if (response.status == 200) {
                const data = await response.json();
                setLicenceDecryptText(data.licence); // Asegúrate de adaptar esto según la respuesta real del servidor
            } else {
                // Puedes manejar otros códigos de estado aquí
                console.error('Error en la solicitud al servidor');
            }
        } catch (error) {
            console.error('Error en la solicitud al servidor', error);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            key: key,
            date: date,
            extra: {
                limit: limit,
                invoices: withInvoices,
                anpr: withAnpr
            }
        }
        console.log(data)

        const apiKey = process.env.REACT_APP_APIKEY_LICENCE

        // Realizar la solicitud al servidor
        try {

            const response = await fetch(`${ServerUrl}/cl`, {
                method: 'POST',
                cors: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                },
                body: JSON.stringify(data),
            });

            // Manejar la respuesta del servidor
            if (response.status == 201) {
                const data = await response.json();
                setLicence(data.licence); // Asegúrate de adaptar esto según la respuesta real del servidor
            } else {
                // Puedes manejar otros códigos de estado aquí
                console.error('Error en la solicitud al servidor');
            }
        } catch (error) {
            console.error('Error en la solicitud al servidor', error);
        }
    };

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Home" breadcrumbItem="Creación de Licencia" />

                    {/* Form Card ... two field 1) Date 2) Key */}
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Crear Licencia</h4>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group row mb-4">
                                            <label htmlFor="horizontal-key-input" className="col-sm-3 col-form-label">Clave</label>
                                            <div className="col-sm-9">
                                                <input className="form-control" autoFocus={true} type="text" id="horizontal-key-input" onChange={(e) => setKey(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <label htmlFor="horizontal-date-input" className="col-sm-3 col-form-label">Fecha de vencimiento</label>
                                            <div className="col-sm-9">
                                                <input className="form-control" type="date" id="horizontal-date-input" onChange={(e) => setDate(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="form-group row mb-4">
                                            <label htmlFor="horizontal-limit-input" className="col-sm-3 col-form-label">Límite de Tickets</label>
                                            <div className="col-sm-9">
                                                <input className="form-control" type="number" value={limit} id="horizontal-limit-input" onChange={(e) => setLimit(parseInt(e.target.value))} />
                                            </div>
                                        </div>

                                        {/* CHECKBOX WITHINVOICES */}
                                        <div className="form-group row mb-4 align-items-center justify-content-center">
                                            <label htmlFor="horizontal-invoices-input" className="col-sm-3 col-form-label">Factura Electrónica</label>
                                            <div className="col-sm-9">
                                                <input type="checkbox" id="horizontal-invoices-input" checked={withInvoices} onChange={(e) => setWithInvoices(e.target.checked)} />
                                            </div>
                                        </div>

                                        {/* CHECKBOX WITH ANPR */}
                                        <div className="form-group row mb-4 align-items-center justify-content-center">
                                            <label htmlFor="horizontal-invoices-input" className="col-sm-3 col-form-label">ANPR</label>
                                            <div className="col-sm-9">
                                                <input type="checkbox" id="horizontal-invoices-input" checked={withAnpr} onChange={(e) => setWithAnpr(e.target.checked)} />
                                            </div>
                                        </div>



                                        <div className="form-group row justify-content-end">
                                            <div className="col-sm-9">
                                                <div>
                                                    <button type="submit" className="btn btn-primary w-md" >Crear Licencia</button>
                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>

                        {/* si hay licencia mostrar en un CARD con un boton para copiar el texto */}
                        {licence && (
                            <div className="col-sm-12 col-md-9 col-lg-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Licencia</h4>
                                        <h5>{licence}</h5>
                                        {/* Button copy text */}
                                        <button className="btn btn-primary w-md" onClick={() => copyToClipboard()}><i className='fa fa-copy'></i> Copiar</button>
                                        {/* Mostrar el mensaje de éxito */}
                                        {showAlert && (
                                            <Alert color="success" className="mt-3">
                                                Licencia copiada al portapapeles
                                            </Alert>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}


                        {/* DECRYPT */}
                        <div className="col-sm-12 col-md-9 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Desencriptar Licencia</h4>
                                    <form onSubmit={handleSubmitDectipt}>
                                        <div className="form-group row mb-4">
                                            <label htmlFor="horizontal-key-input" className="col-sm-3 col-form-label">Licencia</label>
                                            <div className="col-sm-9">
                                                <input className="form-control"  type="text" id="horizontal-key-input" onChange={(e) => setLicenceDecrypt(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="form-group row justify-content-end">
                                            <div className="col-sm-9">
                                                <div>
                                                    <button type="submit" className="btn btn-primary w-md" >Desencriptar</button>
                                                </div>
                                            </div>
                                        </div>

                                        {licenceDecryptText && (<div>{licenceDecryptText}</div>)}

                                        

                                    </form>
                                </div>
                            </div>
                        </div>

                        

                    </div>


                    
                </Container>
            </div>
        </>
    );
}

export default Page;